import axios from 'axios';

export const getRequest = async (url, params = {}) => {
    try {
        const response = await axios.get(url, { params }); 
        return response.data;
    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
};

export const postRequest = async (url, data = {}) => {
    try {
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
};