import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  CardMedia,
  CardActions,
} from "@mui/material";
import "./ProjectPreviewComponent.css"; // Import additional styles if needed
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../header/Header";

const ProjectPreviewComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const projectData = location.state || {};
  const restrictedProjects = projectData?.restrictedProjects || [];
  const otherProjects = projectData?.otherProjects || [];

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, [projectData]); 

  const redirectProject = (data) => {
    isPasskeyEnteredAlready();
    scrollToTop();
    let previewData = {
      projectTitle: data.title,
      projectURL: data.url,
      restrictedProjects: restrictedProjects,
      otherProjects: otherProjects,
    };
    console.log("data = ", data);

    if (data.project_type == "restricted") {
      previewData["projectType"] = "restricted";
      // if(isPasskeyEnteredAlready()){
      //   navigate("/project-preview", { state: previewData });
      // } else {
      //   navigate("/form", { state: previewData });
      // }
      navigate("/form", { state: previewData });
    } else {
      previewData["projectType"] = "nonrestricted";
      navigate("/project-preview", { state: previewData });
      window.location.reload();
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      scrollToTop();
    };

    window.addEventListener('popstate', handlePopState);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Avoiding  Passkey if user already entered Today
  const isPasskeyEnteredAlready = () => {
    const now = new Date();
    let nowTimestamp = now.getTime(); // Get current timestamp in milliseconds

    // Check if it's exactly midnight
    const getStoredTime = localStorage.getItem('storePasskey');

    console.log("Time === ", nowTimestamp, getStoredTime)
    try{
    
      if (Number(nowTimestamp) > Number(getStoredTime) ) {
        localStorage.setItem('storePasskey', nowTimestamp);
        return false
      } else {
        return true
      }
    }
    catch{
       return false
    }
  };


  const ProjectsGrid = ({ projectsList, previewProjectTitle, projectType, heading }) => {
    return (

      <Grid container spacing={3} mt={0}>
        <Grid item xs={12} md={12} sx={{justifyContent:{md:'left',xs:'center', sm:'center'}, textAlign:{md:'left',xs:'center', sm:'center'}, marginBottom:'0px', paddingBottom:'0px'}}>
          <Typography variant="h5" align="left" gutterBottom sx={{color: projectType=='restricted'? '#ff5722' : '#ff5722', marginBottom:'0px', paddingBottom:'0px'}}>
            {heading}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} md={4} sx={{justifyContent:'center', textAlign:'left'}}> */}
          {projectsList?.map((project, ind) =>  project.title != previewProjectTitle && (
              <Grid
                sx={{justifyContent:'center', textAlign:'left', display:'flex'}}
                className="grid-override"
                item
                xs={12}
                sm={4}
                md={4}
                key={ind}
                onClick={() => redirectProject(project)}
              >
                <Card
                  sx={{
                    height: '400px',
                    width: '320px',
                    cursor: "pointer",
                    background: "black",
                    color: "#d6d6d6",
                    borderRadius: "10px",
                    paddingBottom: '0px !important',
                  }}
                >
                  <Box display='flex' width='100%' justifyContent='center' alignItems='center'>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign:'center',
                        fontSize: "14px",
                        textTransform: "none",
                        width: "250px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        height: "55px fit-content",
                        cursor: "default",
                        borderRadius: 2,
                        color: "#d6d6d6",
                        border: "1.5px solid #ff5722",
                        margin: "35px",
                        marginBottom: "14px",
                      }}
                    >
                      {project.category}
                    </Typography>
                  </Box>
    
                  <CardMedia
                    sx={{ height: '150px', width: '320px' }}
                    image={project.thumbnail}
                    title="Project Thumbnail"
                  />
                  <CardContent
                    sx={{
                      height: "calc(100% - 200px - 70px)",
                      display: "flex",
                      flexDirection: "column",
                      margin: '1px',
                      marginTop: '20px'
                    }}
                  >
                    <Typography gutterBottom variant="h6" component="div" fontSize='18px'>
                      {project.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '3', // Specify the number of lines before truncating
                        overflow: 'hidden', // Ensure content is hidden after the line clamp
                        textOverflow: 'ellipsis', // Show "..." at the end
                        fontSize: '14px',
                      }}
                    >
                      {project.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
          ))}
        </Grid>

      // </Grid>

      // <Grid container sx={{ justifyContent: 'center' }}>
      // <Grid item xs={12} sx={{ margin: "1.5rem" }} key={2}>
      //   <Typography variant="h5" align="left" gutterBottom paddingBottom={1} sx={{color: projectType=='restricted'? '#ff5722' : '#ff5722'}}>
      //     {heading}
      //   </Typography>
      // </Grid>
  
      //   {/* <Grid item justifyContent="left" display="flex"> */}
      //   <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
      //     {projectsList?.map((project, ind) => (
      //       <Grid
      //         className="grid-override"
      //         item
      //         xs={12}
      //         sm={4}
      //         md={4}
      //         key={ind}
      //         onClick={() => redirectProject(project)}
      //         sx={{
      //           alignContent: 'center',
      //         }}
      //       >
      //         <Card
      //           sx={{
      //             height: '400px',
      //             width: '320px',
      //             cursor: "pointer",
      //             background: "black",
      //             color: "#d6d6d6",
      //             borderRadius: "10px",
      //             paddingBottom: '0px !important',
      //           }}
      //         >
      //           <Box display='flex' width='100%' justifyContent='center' alignItems='center'>
      //             <Typography
      //               variant="body2"
      //               sx={{
      //                 display: "flex",
      //                 justifyContent: "center",
      //                 alignItems: "center",
      //                 fontSize: "14px",
      //                 textTransform: "none",
      //                 width: "250px",
      //                 paddingLeft: "5px",
      //                 paddingRight: "5px",
      //                 height: "25px",
      //                 cursor: "default",
      //                 borderRadius: 2,
      //                 color: "#d6d6d6",
      //                 border: "1.5px solid #ff5722",
      //                 margin: "35px",
      //                 marginBottom: "14px",
      //               }}
      //             >
      //               {project.category}
      //             </Typography>
      //           </Box>
  
      //           <CardMedia
      //             sx={{ height: '150px', width: '320px' }}
      //             image={project.thumbnail}
      //             title="Project Thumbnail"
      //           />
      //           <CardContent
      //             sx={{
      //               height: "calc(100% - 200px - 70px)",
      //               display: "flex",
      //               flexDirection: "column",
      //               margin: '1px',
      //               marginTop: '20px'
      //             }}
      //           >
      //             <Typography gutterBottom variant="h6" component="div" fontSize='18px'>
      //               {project.title}
      //             </Typography>
      //             <Typography
      //               variant="body2"
      //               sx={{
      //                 display: '-webkit-box',
      //                 WebkitBoxOrient: 'vertical',
      //                 WebkitLineClamp: '3', // Specify the number of lines before truncating
      //                 overflow: 'hidden', // Ensure content is hidden after the line clamp
      //                 textOverflow: 'ellipsis', // Show "..." at the end
      //                 fontSize: '14px',
      //               }}
      //             >
      //               {project.description}
      //             </Typography>
      //           </CardContent>
      //         </Card>
      //       </Grid>
      //     ))}
      //   </Grid>

      // </Grid>
    );
  };


  return (
    <Box sx={{ padding: "20px", marginTop: '3rem' }}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Header/>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            style={{
              color: "#ff520E",
              fontWeight: "500",
              textAlign: "center",
              marginLeft: "1.5rem",
              fontSize: "1.5rem",
            }}
          >
            {projectData.projectTitle}
          </Grid>
          <Grid item style={{ textAlign: "center", marginRight: "1.5rem" }}>
            <Button
              onClick={() => navigate("/all-projects")}
              variant="outlined"
              sx={{
                borderRadius: "10px",
                borderColor: "#ff520E",
                color: "white",
                textTransform: 'none'
              }}
            >
              go to list
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            background: "black",
            margin: "1.5rem",
            marginTop: "1rem",
            padding: "1.5rem",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ height: "1400px", maxHeight: "100vh" }}>
            <iframe
              className="project-frame"
              src={projectData.projectURL}
              title="Project"
              style={{ width: "100%", height: "100%", border: "none" }}
              allowFullScreen
            ></iframe>
          </Box>
        </Grid>

      {projectData.projectType !== "restricted" && (
        <ProjectsGrid 
            projectsList={otherProjects}
            previewProjectTitle={projectData.projectTitle}
            projectType={'open'}
            heading={'View Other Open Projects'}
        />
      )}

      <ProjectsGrid 
          projectsList={restrictedProjects}
          previewProjectTitle={projectData.projectTitle}
          projectType={'restricted'}
          heading={'View Other Restricted Access Projects'}
      />

         

      </Grid>
    </Box>
  );
};

export default ProjectPreviewComponent;
