import React, { useState } from 'react';
import './FormComponent.css'
import { TextField, Button, Grid, Select, MenuItem, InputLabel, FormControl, Box, ThemeProvider, createTheme, Tooltip, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, TextareaAutosize, Typography, Divider } from '@mui/material';
import { PassKeyValidateURL, SendEmailURL } from '../../api-services/api-constants';
import { postRequest } from '../../api-services/api-methods';
import { useLocation, useNavigate } from 'react-router-dom';
import SnackbarComponent from "../snackbar/SnackbarComponent";
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import TermsConditions from './TermsConditions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            height: 40, 
            borderRadius: '8px',
            padding: '0 14px',
            marginBottom: '8px', 
            color: '#D6D6D6',
            background: '#2b2b2b',
            fontSize: 'small'
          },
          '& .MuiOutlinedInput-input': {
            padding: '10px 0', 
            caretColor: 'rgba(255, 82, 14, 1)', // Change cursor color
          },
          '& .MuiInputLabel-root': {
            top: '-5px', 
            fontSize: '0.85rem', 
          },
          '& .MuiInputLabel-shrink': {
            top: '0px', 
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ff6600', // Custom border color when focused
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: 40, 
          borderRadius: '8px',
          padding: '0 14px',
          marginBottom: '5px', 
          color: 'white',
          fontSize: 'small',
          background: '#2b2b2b',
          '& .MuiOutlinedInput-input': {
            padding: '10px 0', 
            caretColor: 'rgba(255, 82, 14, 1)',
            // borderColor: '#ff6600',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            // borderColor: '#ff4500',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ff6600', // Custom border color when focused
          },
          '& .MuiSelect-icon': {
            color: 'white'
          }
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: 'hsl(0deg 0% 21%)',
          color: 'white',
          maxHeight: '12rem'
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 'small',
          '&:hover': {
            backgroundColor: '#2b2b2b', 
          },
        },
      },
    },
    MuiButton : {
      styleOverrides: {
        root: {
          background: '#ff520E',
          borderRadius: '8px',
          textTransform: 'none'
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          paddingTop: '0px !important',
        },
      },
    },
  },
});


const FormComponent = () => {
  const [passkey, setPasskey] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    company: '',
    purpose: '',
    comment: ''
  });

  const [errors, setErrors] = useState({
    passkey: false,
    name: false,
    email: false,
    // phone: false,
    role: false,
    // company: false,
    purpose: false,
    comment: false
  });

  const rolesList = ["HR/Recruiters", "Clients", "Design Team / Product Team", "Executive Leadership", "Investors / Partners", "Educators / Researchers", "Conference Organizers"];
  const purposeList = ["Review and Feedback", "Project Collaboration", "Client Presentation", "Research and Analysis", "Training and Education", "Audit and Compliance", "Bug Fixing and Maintenance", "Temporary Access for Specific Task", "Business Strategy Review"];

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');

  const [termsOpen, setTermsOpen] = useState(false);
  const [checkbox, setCheckbox] =  useState(false)

  const navigate = useNavigate();

  const location = useLocation();
  const projectData = location.state || {};


  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

    let newErrors = {};
    newErrors[event.target.name] = !formData[event.target.name]

    setErrors(newErrors);
  };


  const fetchApi = (type, data=formData) => {
    let apiURL = '';
    if(type=='passkey') {
      apiURL = PassKeyValidateURL;
    } else {
      apiURL = SendEmailURL;
    }

    return postRequest(apiURL, data)
  }

  const redirectProject = ()=> {
    let previewData =  projectData['previewData'];
    console.log("formPreviewData = ", projectData, previewData)
    navigate('/project-preview', {state: projectData});
    
    let currentDate = new Date();
    let currentTimestamp = currentDate.getTime(); // Get current timestamp in milliseconds

    let extendedDate = new Date(currentDate); // Create a copy of the current date

    // Extend the date by 1 minute
    extendedDate.setMinutes(extendedDate.getMinutes() + 60);
    let extendedDateTimestamp = extendedDate.getTime(); // Get current timestamp in milliseconds

    localStorage.setItem('storePasskey', extendedDateTimestamp);
  }

  const passkeyValidation = async () => {
    if(passkey){
      setErrors({passkey: false})
    } else {
      setErrors({passkey: true})
    }

    if(passkey){
      const response = await fetchApi('passkey', {passkey: passkey})
      console.log("status = ", response)
      if(response.status) {
        setSnackbarMessage('Redirecting to the Project... ');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => {
          redirectProject();
        }, 1500);
      } else {
        setSnackbarMessage('Please enter the valid Passkey!');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      
    } else {
      setSnackbarMessage('Please enter your Passkey!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }


    if(passkey){
      
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    let newErrors = {
      name: !formData.name, 
      email: !formData.email,
      // phone: !formData.phone,
      role: !formData.role,
      // company: !formData.company,
      purpose: !formData.purpose,
      // comment: !formData.comment
    };

    setErrors(newErrors);
    
    for (let key of Object.keys(formData)) {
      if (!formData[key]) { 
          if(!['phone', 'company', 'comment'].includes(key)){
            setSnackbarMessage('Please fill the highlighted mandatory fields!');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;  
          }
      }
    }

    if(checkbox==false) {
      setSnackbarMessage('Please check the Terms & Conditions!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return
    }

    const response = await fetchApi('getPasskey');
    if(response.status) {
      setSnackbarMessage(response.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage(response.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCheckbox = () => {
    setCheckbox(!checkbox)
  }

  const handleDialogOpen = () => {
    setTermsOpen(true);
  };

  const handleDialogClose = () => {
    setTermsOpen(false);
  };


  return (
    <div>
      <div style={{textAlign:'right', marginRight:'2rem', marginTop: '2rem'}}>
          <HighlightOffSharpIcon sx={{cursor: 'pointer', color: 'white'}} onClick={()=>navigate('/all-projects')}/>
      </div>
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        // backgroundColor: '#1e1e1e',
        padding: '1rem',
        borderRadius: '8px',
        // color: '#fff',
        maxWidth: '700px',
        height: '80vh',
        margin: '0.5rem auto',
      }}
    >

      <ThemeProvider theme={theme}>
        <Grid container spacing={2}  sx={{margin: '5px'}}>

          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item sx={{}}>
              <h3 style={{marginRight:'1rem', marginLeft: '1.5rem', marginTop: '0rem', fontWeight: '500'}}>Please check your e-mail for the passkey from <span style={{textDecoration: 'underlined'}}>"uxbyrk@gmail.com"</span></h3>
            </Grid>

            <Grid item xs={8}>
              <label htmlFor="passkey" style={{ display: 'block', marginBottom: 5 }}>
                Passkey
              </label>
              <TextField
                fullWidth
                variant="outlined"
                type='text'
                name="passkey"
                value={passkey}
                error={errors.passkey}
                onChange={(e) => setPasskey(e.target.value)}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#2b2b2b',
                    color: '#fff',
                  },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={passkeyValidation}
                sx={{
                  backgroundColor: '#ff6600',
                  color: '#fff',
                  marginTop: '1rem',
                  '&:hover': {
                    backgroundColor: '#ff4500',
                  },
                }}
                variant="contained"
                fullWidth
              >
                Go!
              </Button>
            </Grid>
            <Grid item xs={11}>
              <Divider sx={{ backgroundColor: '#fff', marginTop: '1rem', height: '0.5px' }} />
            </Grid>
          </Grid>

          


          <Grid container justifyContent='center' alignItems='center' spacing={2} >
          <div style={{marginTop:'2rem', marginBottom:'1rem', justifyContent: 'center', textAlign:'center'}}>
            <h3 style={{marginBottom:'1rem', fontWeight: '500'}}>Get your access now!</h3>
            <p style={{margin:'0px'}}>You will get an email from "uxbyrk@gmail.com" about your access request soon!</p>
          </div>

          <Grid item xs={6}>
            <label htmlFor="passkey" style={{ display: 'block', marginBottom: 5 }}>
                Name
            </label>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="name"
              value={formData.name}
              error={errors.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <label htmlFor="passkey" style={{ display: 'block', marginBottom: 5 }}>
                Email
            </label>
            <TextField
              required
              fullWidth
              type='email'
              variant="outlined"
              name="email"
              value={formData.email}
              error={errors.email}
              onChange={handleChange}
            />
          </Grid>
          
          <Grid item xs={6}>
            <label htmlFor="passkey" style={{ display: 'block', marginBottom: 5 }}>
                Phone
            </label>
            <PhoneInput
              className='phone-input'
              country={'us'}
              name='phone'
              value={formData.phone}
              onChange={(value) => {
                setFormData({ ...formData, phone: '+' + value });
                setErrors({ ...errors, phone: !value });
              }}
            />           
          </Grid>

          <Grid item xs={6}>
            <label htmlFor="passkey" style={{ display: 'block', marginBottom: 5, marginTop:5 }}>
                Role
            </label>
            <FormControl fullWidth variant="outlined">
              <Select
                required
                name="role"
                value={formData.role}
                error={errors.role}
                onChange={handleChange}
              >
                {rolesList.map(item=>(
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <label htmlFor="passkey" style={{ display: 'block', marginBottom: 5 }}>
                Company
            </label>
            <TextField
              fullWidth
              variant="outlined"
              name="company"
              // value={formData.company}
              error={errors.company}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <label htmlFor="passkey" style={{ display: 'block', marginBottom: 5}}>
                Purpose of Access
            </label>
            <FormControl fullWidth variant="outlined">
              <Select
                required
                name="purpose"
                value={formData.purpose}
                error={errors.purpose}
                onChange={handleChange}
              >
                {purposeList.map(item=>(
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="passkey" style={{ display: 'block', marginBottom: 5 }}>
                Comment
            </label>
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '12px', // Increase internal padding
                  height: '4rem', // Set height for the input field
                },
                '& .MuiOutlinedInput-inputMultiline': {
                  height: 'auto', // Ensures multiline behavior is respected
                }
              }}
              variant="outlined"
              name="comment"
              multiline
              minRows={2} 
              maxRows={2} 
              fullWidth
              value={formData.comment}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} textAlign='center'>
            <Tooltip title="Click here to agree with our Privacy Policy" arrow>
            <FormControlLabel
  control={
    <Checkbox
      onClick={handleCheckbox}
      checked={checkbox}
      sx={{
        color: '#ff520E',
        '&.Mui-checked': {
          color: '#ff520E',
        },
      }}
    />
  }
  label={
    <Typography>
      I agree to the{' '}
      <span
        onClick={handleDialogOpen}
        style={{
          borderBottom: '2px solid #ff520E', 
          color: '#ff520E', 
        }}
      >
        Terms & Conditions
      </span>
    </Typography>
  }
/>
            </Tooltip>

            <Dialog open={termsOpen} onClose={handleDialogClose} sx={{background:"black"}} maxWidth='lg' fullWidth>
              <Box sx={{background: '#1E1E1E', color: 'white'}}>

                <DialogTitle sx={{fontWeight:'bold', fontSize: '1.5rem', padding:'0.8rem' }} >Terms & Conditions</DialogTitle>
                <DialogContent>
                  <TermsConditions/>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} sx={{color: 'white'}}>Close</Button>
                </DialogActions>
              </Box>
            </Dialog>
          </Grid>

          <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{margin:'0.8rem'}}>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  borderColor: '#fff',
                  color: '#fff',
                  background: 'transparent',
                  '&:hover': {
                    backgroundColor: '#2b2b2b',
                    borderColor: '#ff6600',
                  },
                }}
                onClick={()=>navigate('/all-projects')}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>

      <SnackbarComponent
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

    </Box>
    </div>
  );
};

export default FormComponent;
