import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Instagram } from '@mui/icons-material';
import { Grid, Link } from '@mui/material';
import Logo from './Logo.png'

const pages = [
    {name:'home', url:'https://www.uxbyrk.com/'},
    {name:'about', url:'https://www.uxbyrk.com/about'},
    {name:'work', url:'https://www.uxbyrk.com/work'},
    {name:'services', url:'https://www.uxbyrk.com/services'}
];
const linkedInLink = 'https://www.linkedin.com/in/radhakrishnaaekbote/';
const instagramLink = 'https://www.instagram.com/radhakrishna_aekbote/';

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const redirectLink = (url) => {
    window.open(url)
  };

  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ padding: '0.2rem', background:'transparent', backdropFilter: 'blur(10px)', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100 }}>
      
      {/* Mobile Menu for small screens */}
      <Grid item xs={2} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'left' }}>
        <IconButton
          size="large"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          {pages.map((page) => (
            <MenuItem
              key={page.name}
              onClick={() => redirectLink(page.url)}
              sx={{
                '&:hover': {
                  filter: 'drop-shadow(0px 0px 10px #ff5722)',
                },
              }}
            >
              <Typography sx={{ textAlign: 'center', width: '100%' }}>{page.name}</Typography>
            </MenuItem>
          ))}
        </Menu>

        <img
          onClick={() => redirectLink(pages[0].url)}
          src={Logo}
          height={40}
          style={{
            marginBottom: '10px',
            marginLeft: '2rem',
            cursor: 'pointer',
          }}
        />
      </Grid>

      {/* Logo Section for Desktop */}
      <Grid item xs={2} md={4} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'left' }}>
        <img
          onClick={() => redirectLink(pages[0].url)}
          src={Logo}
          height={40}
          style={{
            marginBottom: '10px',
            marginLeft: '6rem',
            cursor: 'pointer',
          }}
        />
      </Grid>

      {/* Centered Pages Menu for Desktop */}
      <Grid item xs={4} md={4} container justifyContent="center">
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
          }}
        >
          {pages.map((page) => (
            <Typography
              key={page.name}
              onClick={() => redirectLink(page.url)}
              sx={{
                my: 2,
                color: '#d6d6d6',
                display: 'block',
                marginRight: '28px',
                textDecoration: 'none',
                cursor: 'pointer',
                transition: 'color 0.3s ease, textDecoration 0.3s ease',
                '&:hover': {
                  textDecoration: 'underline',
                  color: '#ff5722',
                  filter: 'drop-shadow(0px 0px 10px #ff5722)',
                },
              }}
            >
              {page.name}
            </Typography>
          ))}
        </Box>
      </Grid>

      {/* Social Media Icons */}
      <Grid item xs={4} md={4} container justifyContent="right" sx={{ display: 'flex' }}>
        <LinkedInIcon
          onClick={() => redirectLink(linkedInLink)}
          sx={{
            marginRight: '28px',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
              color: '#ff5722 !important',
              filter: 'drop-shadow(0px 0px 10px #ff5722)',
            },
          }}
        />
        <Instagram
          onClick={() => redirectLink(instagramLink)}
          sx={{
            cursor: 'pointer',
            marginRight: {md:'100px'},
            '&:hover': {
              textDecoration: 'underline',
              color: '#ff5722 !important',
              filter: 'drop-shadow(0px 0px 10px #ff5722)',
            },
          }}
        />
      </Grid>
    </Grid>

  );
}

export default Header;
