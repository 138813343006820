import React, { useEffect, useState } from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';

// Import your components
import FormComponent from './components/form/FormComponent';
import Projects from './components/projects/Projects';
import LandingPage from './components/landingpage/LandingPage';
import AllProjects from './components/allProjects/AllProjects';
import ProjectPreviewComponent from './components/project-preview/ProjectPreviewComponent';
import Header from './components/header/Header';

function useQuery() {
  const { search } = useLocation();
  return new URLSearchParams(search);
}



function App() {
  const [projectType, setProjectType] = useState('open');

  function ThemeHandler() {
    const query = useQuery();
    const theme = query.get('theme');
    const pType = query.get('project_type')
    setProjectType(pType)    
  
  
    if(theme){
      localStorage.setItem('uxbyrkTheme', theme)
    }
   
    let userTheme = localStorage.getItem('uxbyrkTheme');
  
    if(userTheme=='white'){
      document.body.style.background = "#D6D6D6";
      document.body.style.color = "black";
    } else {
      document.body.style.background = "#1E1E1E";
      document.body.style.color = "#d6d6d6";
    }
  
    return null; // This component doesn't render anything
  }
  
  return (
    <Router>
      <ThemeHandler/>
      <div>
        <Routes>
          <Route path="/project-preview" element={<ProjectPreviewComponent />} />
          <Route path="/all-projects" element={<AllProjects />} />
          <Route path="/form" element={<FormComponent />} />

          {/* If User Provided Invalid Route */}
          <Route path="*" element={<Navigate to="/all-projects" replace />} />
        </Routes>
      </div>
    </Router>

    // <iframe style={{border: '1px solid rgba(0, 0, 0, 0.1)', width:"100vw", height:"99vh"}} src="https://embed.figma.com/proto/fAt5V6mBASlV5X46tMCAtk/Radhakrishna-Aekbote-%7C-Lead-UXD?node-id=1-2316&node-type=frame&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&embed-host=share" allowfullscreen></iframe>
  );
}

export default App;
