import React, { useEffect, useState } from "react";
import "./AllProjects.css";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Button,
  Box,
  Grid,
  Skeleton,
} from "@mui/material";
import { getRequest } from "../../api-services/api-methods";
import { AllProjectsURL } from "../../api-services/api-constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { defaultProjects } from "./defaultProjects";
import CryptoJS from 'crypto-js';
import Header from "../header/Header";

const MovieCard = ({ project, allProjects }) => {
  const navigate = useNavigate();
  const restrictedProjects = allProjects.filter(
    (data) => data.project_type == "restricted"
  );
  const otherProjects = allProjects.filter(
    (data) => data.project_type == "nonrestricted"
  );

  const [decryptedUrls, setDecryptedUrls] = useState([]);

  useEffect(() => {
    // The secret key must match the one used in Python
    const secretKey = 'thisisakey123456'; // Must be same as used in Python

    // List of encrypted URLs received from the Python backend
    const encryptedUrls = [
      'EncryptedUrl1FromPython',  // Replace with encrypted string
      'EncryptedUrl2FromPython',  // Replace with encrypted string
    ];

    // Function to decrypt an AES encrypted message
    const decryptMessage = (encryptedMessage) => {
      const bytes = CryptoJS.AES.decrypt(encryptedMessage, CryptoJS.enc.Utf8.parse(secretKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return bytes.toString(CryptoJS.enc.Utf8).trim();  // Trim to remove padding spaces
    };

    // Decrypt all URLs
    const decrypted = encryptedUrls.map((url) => decryptMessage(url));
    setDecryptedUrls(decrypted);
  }, []);

  const redirectProject = (data) => {
    let previewData = {
      projectTitle: data.title,
      projectURL: data.url,
      restrictedProjects: restrictedProjects,
      otherProjects: otherProjects,
    };

    if (data.project_type == "restricted") {
      previewData["projectType"] = "restricted";
      // if(isPasskeyEnteredAlready()){
      //   navigate("/project-preview", { state: previewData });
      // } else {
      //   navigate("/form", { state: previewData });
      // }
      navigate("/form", { state: previewData });
    } else {
      previewData["projectType"] = "nonrestricted";
      navigate("/project-preview", { state: previewData });
    }
  };

  // Avoiding  Passkey if user already entered Today
  const isPasskeyEnteredAlready = () => {
    const now = new Date();
    let nowTimestamp = now.getTime(); // Get current timestamp in milliseconds

    // Check if it's exactly midnight
    const getStoredTime = localStorage.getItem('storePasskey');

    console.log("Time === ", nowTimestamp, getStoredTime)
    try{
    
      if (Number(nowTimestamp) > Number(getStoredTime) ) {
        localStorage.setItem('storePasskey', nowTimestamp);
        return false
      } else {
        return true
      }
    }
    catch{
       return false
    }
  };

  return (
    <Card
      className="btn btn-1"
      sx={{
        justifyContent:'center',
        padding: 0,
        width: '1000px',
        height: '150px',
        backgroundColor: "#0D0D0D",
        color: "#d6d6d6",
        borderRadius: 2,
        marginBottom: '0rem',
        cursor: "pointer",
      }}
      onClick={() => redirectProject(project)}
    >
      <div style={{ display: "flex" }}>
        <CardMedia
          component="img"
          sx={{
            width: '320px',
            height: '150px',
            color: "#d6d6d6",
            backgroundColor: "#f0f0f0",
          }}
          image={project.thumbnail}
          alt={project.title}
        />
        <Box sx={{ padding: 0 }}>
          <CardContent sx={{ paddingTop: 0 }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "18px",
                textTransform: "none",
                width: "fit-content",
                height: "24px",
                paddingRight: "8px",
                paddingLeft: "8px",
                cursor: "default",
                borderRadius: 2,
                color: "#d6d6d6",
                border: "1.5px solid #ff5722",
                marginBottom: '20px',
                marginTop: '10px'
              }}
            >
              {project.category}
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
              {project.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#d6d6d6",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                wordBreak: "break-word",
              }}
            >
              {project.description}
            </Typography>
          </CardContent>
        </Box>
      </div>
    </Card>
  );
};

const MovieSection = ({ projectType, sectionTitle, projects, allProjects, loader }) => {
  return (
    <div style={{ margin: "2rem" }}>
      <Typography
        variant="h5"
        sx={{
          justifyContent:'center', textAlign:'center',
          color: projectType == "restricted" ? "#ff5722" : "#ff5722",
          '&:hover':{textDecoration:'none'}
        }}
      >
        {sectionTitle}
      </Typography>

      <Box sx={{ margin: "10rem", marginTop: "2rem", marginBottom: "5px", justifyContent:'center' }}>
        <Grid container spacing={2} justifyContent='center'>
          {projects.map((project, index) => (
            <Grid item key={index}>
              <MovieCard project={project} allProjects={allProjects} />
            </Grid>
          ))}
          <div>
            { loader &&
                (
                  <SkeletonLoader/>
                )
            }
          </div>
        </Grid>
      </Box>
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <Box      
      display="flex"
      sx={{
        color: "#d6d6d6",
        width: '1000px',
        height: '150px',
        borderRadius: "10px",
        overflow: "hidden",
        margin: '1rem',
        marginLeft: "2rem",
        background: "linear-gradient(to right, black, #5a2c00)"
      }}
    >
      {/* Left image skeleton */}
      <Skeleton
        variant="rectangular"
        width="32%"
        height={150}
        sx={{ borderRadius: '5px 0px 0px 0px', background: '#444' }}
      />

      {/* Right content skeleton */}
      <Box
        display="flex"
        flexDirection="column"
        // justifyContent="center"
        sx={{ flex: 1, background: "linear-gradient(to right, black, #5a2c00)" }}
      >
        {/* Heading Skeleton */}
        <Skeleton
          variant="text"
          height={40}
          sx={{ fontSize: '1.5rem', width: '40%', marginLeft: '1rem', marginBottom: '0.8rem', backgroundColor: "#444" }}
        />
          
        <Skeleton variant="text" width="50%" height={30} sx={{ background: "#444", marginLeft: '1rem', }} />
        {/* Description Skeleton */}
        <Skeleton variant="text" width="90%" height={30} sx={{ marginTop:'1rem', marginLeft: '1rem', background: "#444" }} />
        <Skeleton variant="text" width="80%" />
      </Box>
    </Box>
  );
};


const AllProjects = () => {
  const [allProjects, setAllProjects] = useState(defaultProjects);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [projectType, setProjectType] =  useState('open');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const project_type = queryParams.get('project_type');
  if(project_type){
    localStorage.setItem('project_type', project_type);
  }

  useEffect(() => {

    var projectType = localStorage.getItem('project_type');
    
    setProjectType(projectType);

    const fetchProjects = async () => {
      try {
        const response = await getRequest(AllProjectsURL);
        let temp_projects = [...allProjects, ...response];  // You can store the API response in a temporary variable if needed
        // setTimeout(() => {
          setLoader(false)
          setAllProjects(temp_projects);
        // }, 5000);
        console.log("Fetched Projects:", response);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const restrictedProjects = allProjects.filter(
    (data) => data.project_type == "restricted"
  );
  const otherProjects = allProjects.filter(
    (data) => data.project_type == "nonrestricted"
  );

  return (
    <Box style={{ padding: "10px", marginTop:'3rem' }}>
      <Header/>
      {projectType=='restricted' ?
        <>
        <MovieSection
          projectType="restricted"
          sectionTitle="Restricted Access Projects"
          projects={restrictedProjects}
          allProjects={allProjects}
          loader = {loader}
        />
        <MovieSection
          projectType="nonrestricted"
          sectionTitle="Open Projects"
          projects={otherProjects}
          allProjects={allProjects}
          loader = {loader}
        />
  
        </> :
        <>
          <MovieSection
            projectType="nonrestricted"
            sectionTitle="Open Projects"
            projects={otherProjects}
            allProjects={allProjects}
            loader = {loader}
          />
          <MovieSection
            projectType="restricted"
            sectionTitle="Restricted Access Projects"
            projects={restrictedProjects}
            allProjects={allProjects}
            loader = {loader}
          />
        </> 
        
      } 
      {/* <MovieSection
        projectType="nonrestricted"
        sectionTitle="Open Projects"
        projects={otherProjects}
        allProjects={allProjects}
        loader = {loader}
      />
      <MovieSection
        projectType="restricted"
        sectionTitle="Restricted Access Projects"
        projects={restrictedProjects}
        allProjects={allProjects}
        loader = {loader}
      /> */}
    </Box>
  );
};

export default AllProjects;
