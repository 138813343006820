// const BaseURL = 'https://ux-by-rk-be.onrender.com';
// const BaseURL = 'http://127.0.0.1:5000';
const BaseURL = 'https://ux-by-rk-be-0kn9.onrender.com';

const allProjects = '/all-projects';
const passkeyValidate = '/passkey-validate'
const sendEmail = '/send-approve-email';

export const BASE_URL = BaseURL
export const AllProjectsURL = BaseURL + allProjects;
export const SendEmailURL = BaseURL + sendEmail;
export const PassKeyValidateURL = BaseURL + passkeyValidate;