import React from 'react'

function TermsConditions() {
  return (
    <div style={{maxHeight: '60vh'}}>

<h3>1. Eligibility & Purpose of Access</h3>
<ul>
    <li><p><p>Access is restricted to individuals who have registered through this site with valid, verifiable information.</p></p></li>
    <li><p>Access is granted solely for legitimate purposes such as educational research or professional inquiry and may not be used for any form of competitive, commercial, or personal gain. Misrepresentation of intent or affiliation is strictly prohibited and subject to legal prosecution.</p></li>
</ul>

<h3>2. Ethical Compliance</h3>
<ul>
    <li><p>You are bound by ethical conduct, including but not limited to:</p></li>
    <li><p>No Disclosures: You agree not to share, disseminate, or discuss any of the content with third parties without express written consent from the site owner.</p></li>
    <li><p>No Misuse: You agree to use the material only for its intended and lawful purposes, refraining from any activity that could exploit the content to harm the owner, their clients, or other parties.</p></li>
    <li><p>Reporting Obligations: Any unethical behavior, security breach, or suspicious activity observed in connection with these materials must be immediately reported to the site owner.</p></li>
</ul>

<h3>3. Zero Tolerance on Content Misuse</h3>
<ul>
    <li><p>Strict Prohibition on Copying or Reproducing: You are explicitly forbidden from downloading, copying, photographing, screen-capturing, or recording any of the material, whether visual, text-based, or otherwise. This includes, but is not limited to, screenshots, video recordings, audio recordings, or written documentation of the projects.</p></li>
    <li><p>Misuse for Competitive Purposes: Any attempt to use the confidential content for competitive analysis, reverse engineering, or the development of similar products will result in immediate legal action.</p></li>
    <li><p>Restricted Sharing: Under no circumstances may you transmit access credentials to another individual or entity. Doing so will be treated as a severe breach of contract.</p></li>
</ul>

<h3>4. Intellectual Property and Content Ownership</h3>
<ul>
    <li><p>Ownership: All project assets, designs, materials, and related intellectual property are fully owned by the site owner or respective third-party clients. You acknowledge that you do not hold any right, title, or interest in any of the materials.</p></li>
    <li><p>Legal Protections: All content is protected by international copyright, trade secret, and intellectual property laws. Any form of reproduction, adaptation, or claim of ownership will be met with the strictest legal actions permissible under local and international law.</p></li>
</ul>

<h3>5. Compliance with Non-Disclosure Obligations</h3>
<ul>
    <li><p>You are entering a legal obligation equivalent to a non-disclosure agreement (NDA). Access to these materials signifies that you are aware of, and will comply with, all NDA-related responsibilities, even if you have not personally signed a formal NDA for the content in question.</p></li>
    <li><p>Criminal and Civil Liabilities: Violation of this agreement may result in both civil liability (including substantial financial damages) and criminal liability (including penalties for fraud, intellectual property theft, or breach of fiduciary duties).</p></li>
</ul>

<h3>6. Security Responsibilities</h3>
<ul>
    <li><p>Unique Credentials: Access to confidential projects is provided via unique password-protected credentials. These credentials are for your exclusive use and must be safeguarded diligently. If you suspect any unauthorized access, it must be reported immediately.</p></li>
    <li><p>Audit of Access: Your usage and behavior on the site will be monitored and logged. Any unusual, unauthorized, or unethical behavior will trigger an automatic review and may lead to access being revoked.</p></li>
    <li><p>Termination of Access: The site owner reserves the right to revoke access at any time for violations or potential risks to confidentiality.</p></li>
</ul>

<h3>7. Prohibited Conduct</h3>
<ul>
    <li><p>Commercial Exploitation: You are strictly forbidden from using the accessed content in any form of presentation, business pitch, competitive analysis, publication, or any activity that may exploit the information for financial gain.</p></li>
    <li><p>Harmful Intent: Any intent to harm the reputation, credibility, or financial stability of the site owner or their clients by means of the disclosed information will be met with immediate legal action, including injunctions and financial compensation.</p></li>
</ul>

<h3>8. Legal Enforcement & Remedies</h3>
<ul>
    <li><p>Legal Compliance: You are legally obligated to comply with all intellectual property laws, non-disclosure laws, and confidentiality agreements, both domestic and international.</p></li>
    <li><p>Penalties for Breach: In the event of a breach, you may be subject to:</p></li>
    <ul>
        <li><p>Financial Penalties: For damages incurred as a result of content misuse, with compensation equivalent to the financial loss or potential loss to the owner or clients.</p></li>
        <li><p>Injunctions: Immediate cessation of any unauthorized use or distribution of the material.</p></li>
        <li><p>Criminal Prosecution: Where applicable, breaches may also result in criminal prosecution, including charges of theft, fraud, or intellectual property infringement.</p></li>
    </ul>
</ul>

<h3>9. Dispute Resolution and Governing Law</h3>
<ul>
    <li><p>Jurisdiction: Any breach that necessitates court involvement will be adjudicated under the courts of respective jurisdiction.</p></li>
</ul>

<h3>10. Acknowledgment and Acceptance of Terms</h3>
<ul>
    <li><p>By checking the box below and submitting your request for access, you acknowledge that you have read, understood, and agreed to abide by these Terms and Conditions in full. You also agree that any breach of these terms may result in immediate suspension of access and legal action.</p></li>
</ul>
    </div>
  )
}

export default TermsConditions